@media print {
    @page {
        size: auto; /* Ajuste la taille de la page */
        margin: 2mm 5mm 17mm 5mm;
        padding: 10px;

        @bottom-left-corner {
            content: "Page " counter(page);
        }

        @bottom-left-corner {
            content: "Page " counter(page);
        }
        .print-column {
            grid-column: 2;
        }
        .color {
            color: white;
        }
    }
}
