#dashboard-top-container {
    height: calc(45vh + 65px);
    padding-top: 65px;
}

@media (max-width: 640px) {
    .mdg-content:has(#dashboard-top-container) {
        height: calc(100dvh - 64px) !important;
    }
}

.mdg-section:has(#dashboard-top-container) {
    padding-top: 0px !important;
}

body:has(#dashboard-top-container) #mobile-header {
    background: transparent;
}
#mobile-header {
    background: black;
}

body:has(#dashboard-top-container) #mobile-header.scrolling {
    background-color: rgba(0, 0, 0, 0.75) !important;
}

body:has(#dashboard-top-container) #mobile-header.scrolling2 {
    background-color: rgba(0, 0, 0, 1) !important;
}

.scrolling3,
.scrolling4 {
    display: none;
}
